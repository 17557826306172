<template>
    <Head>
        <title>Default Title</title>
        <meta type="description" content="Default meta description" head-key="description"/>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />
        <link rel="stylesheet" href="https://rsms.me/inter/inter.css">
    </Head>
    <main data-theme='default'>
        <section class="">
            <slot />
        </section>
    </main>
</template>

<script setup>

</script>

<script>

export default {
    props: {

    }
}
</script>

<style>
    main {
        background-color: var(--background-color);
    }
</style>
